<template>
  <v-row class="details-form">
    <v-col cols="12" sm="6">
      <v-select
        v-model="form.body_type"
        :items="bodyTypes"
        item-text="name"
        item-value="id"
        label="Body Type"
        :error-messages="form.$getError('body_type')"
        outlined
      >
      </v-select>
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        v-model="form.calories"
        label="Calories"
        :error-messages="form.$getError('calories')"
        outlined
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        v-model="form.restriction_code"
        label="Template Name or Restriction"
        :error-messages="form.$getError('restriction_code')"
        outlined
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-text-field
        v-model="form.template_number"
        label="Template Number or Diff Meals"
        :error-messages="form.$getError('template_number')"
        outlined
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import Form from '@/utils/form'

export default {
  name: 'MealGuideDetailsForm',

  props: {
    image: {
      type: Object,
    },
    formData: {
      type: Form,
      required: true,
    },
  },

  components: {},

  data() {
    return {
      form: this.formData,
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },

  computed: {
    ...mapState({
      bodyTypes: (state) => state.meals.extra.bodyTypes,
    }),
  },
}
</script>
