<template>
  <div class="new-program">
    <div v-if="form">
      <v-row>
        <v-col sm="8">
          <div class="d-flex justify-space-between align-center mb-4">
            <h2 class="mr-auto">Meals Details</h2>
            <v-btn
              class="primary--text mr-5"
              text
              depressed
              :to="{ name: 'active.meals' }"
            >
              <span>Cancel</span>
            </v-btn>

            <v-btn
              class="bg-primary-gradient primary next-button px-12"
              @click="saveAndNext"
              :loading="form.$busy"
              text
              depressed
            >
              Save & Next
              <v-icon> {{ icons.next }} </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="8">
          <form autocomplete="off">
            <details-form :form-data="form" />
          </form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DetailsForm from '../components/DetailsForm'
import { mapActions, mapState } from 'vuex'
import { mdiArrowRight } from '@mdi/js'
import Form from '@/utils/form'
import { pick } from 'lodash'

export default {
  name: 'MealDetailsTab',

  components: {
    DetailsForm,
  },

  data() {
    return {
      icons: {
        next: mdiArrowRight,
      },
      form: new Form({
        id: this.$attrs.id,
        body_type: null,
        restriction_code: null,
        template_number: null,
        calories: 0,
      }),
    }
  },

  computed: {
    ...mapState({
      selectedData: (state) => state.meals.selectedData,
    }),
  },

  methods: {
    ...mapActions({
      getSingleData: 'meals/getSingleData',
      saveData: 'meals/saveData',
    }),

    async fetchData(id) {
      let data = await this.getSingleData({ id })
      this.fillForm(data)
    },

    saveAndNext() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()
      payload.completed = true

      this.saveData(payload)
        .then((data) => {
          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({
              name: 'form.meals.guide',
              params: { id: this.$attrs.id },
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    fillForm(data) {
      data = pick(data, [
        'id',
        'body_type',
        'restriction_code',
        'template_number',
        'calories',
      ])

      this.form = new Form(data)
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },
}
</script>
